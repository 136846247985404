import { accountAccessShowLogin } from 'actions/UiActions'
import { BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE, BUSINESS_TRAVELLER_ROOT_ROUTE } from 'businessTraveller/routes/businessTravellerRoutes'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Clickable from 'components/Common/Clickable/Clickable'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane/Pane'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import { useAppDispatch } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React, { useCallback } from 'react'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};

  ${mediaQueryUp.tablet} {
    border: none;
  }
`

const BannerContentGroup = styled(Group)`
  height: ${rem(40)};
`

const HeaderContentGroup = styled(Group)`
  height: ${rem(64)};

  ${mediaQueryUp.tablet} {
    border: none;
    height: ${rem(74)};
  }
`

function BusinessTravellerGuestHeader() {
  const appDispatch = useAppDispatch()

  const openLogin = useCallback(() => {
    appDispatch(accountAccessShowLogin())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Group direction="vertical">
      <Pane type="light-grey">
        <LayoutContainer>
          <BannerContentGroup
              direction="horizontal"
              verticalAlign="center"
              horizontalAlign="center"
              tabletHorizontalAlign="end"
            >
            <TextButton
                kind="tertiary"
                href={config.businessTraveller.businessAdminPortalHref}
                target="_blank"
              >
              Business Traveller Admin
            </TextButton>
          </BannerContentGroup>
        </LayoutContainer>
      </Pane>

      <HeaderContainer>
        <LayoutContainer>
          <HeaderContentGroup
              direction="horizontal"
              horizontalAlign="space-between"
              gap={8}
              verticalAlign="center"
            >
            <Clickable to={BUSINESS_TRAVELLER_ROOT_ROUTE}>
              <LogoIcon/>
            </Clickable>

            <Group direction="horizontal" gap={8}>
              <TextButton kind="tertiary" onClick={openLogin}>
                Log in
              </TextButton>

              <CSSBreakpoint min="tablet">
                <TextButton kind="primary" to={BUSINESS_TRAVELLER_BUSINESS_SIGNUP_ROUTE}>
                  Join now for free
                </TextButton>
              </CSSBreakpoint>
            </Group>
          </HeaderContentGroup>
        </LayoutContainer>
      </HeaderContainer>
    </Group>
  )
}

export default BusinessTravellerGuestHeader
